import * as React from "react";
import { useState } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { grey } from "@mui/material/colors";
import { keyframes } from "@mui/system";
import { Paper } from "@mui/material";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://neozelts.com/">
        NEOZELTS.COM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Album() {
  const [state, setState] = useState(products);
  function changePhoto(itemId, n) {
    const chagedProduct = products.find((el) => el.id === itemId);
    chagedProduct.imageIndex = n;
    setState({
      ...state,
      chagedProduct,
    });
    console.log(chagedProduct);
  }

  //function selectPhoto(itemId) {
  //  const selected = Math.floor(Math.random() * 3);
  //  changePhoto(itemId, selected);
  //  return selected.toString();
  //}
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <main>
        <Container sx={{ py: 5 }} maxWidth="lg">
          {/* Hero unit */}
          <Paper
            elevation={8}
            sx={{
              backgroundColor: "grey.800",
              color: "#fff",
              mb: 4,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage: "url(image.png)",
            }}
          >
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: "none" }}
                src="/image.png"
                alt="wallpaper"
              />
            }

            <Container maxWidth="sm">
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Box
                  component="img"
                  sx={{
                    marginTop: 5,
                    height: 300,
                    width: 300,
                    maxHeight: { xs: 100, md: 160 },
                    maxWidth: { xs: 100, md: 160 },
                    animation: `${spin} 5s linear 0s infinite`,
                  }}
                  alt="Logo icon"
                  src="/LogoBlack1.png"
                  loading="lazy"
                />
                <Box
                  component="img"
                  sx={{
                    marginTop: 5,
                    marginBottom: 5,
                    height: 100,
                    width: 850,
                    maxHeight: { xs: 30, md: 80, lg: 100 },
                    maxWidth: { xs: 260, md: 680, lg: 850 },
                  }}
                  alt="Logo Text"
                  src="/logoText.png"
                  loading="lazy"
                />
              </Stack>
            </Container>
          </Paper>

          {/* End hero unit */}
          <Grid container spacing={4}>
            {products.map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={3}>
                <Paper elevation={8}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="div"
                      sx={{
                        // 16:9
                        pt: "100%",
                      }}
                      image={item.images[item.imageIndex]}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {item.title}
                      </Typography>
                      <Typography>{item.desctription}</Typography>
                    </CardContent>
                    <CardActions>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Select color
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={item.imageIndex.toString()}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="0"
                            onChange={() => changePhoto(item.id, 0)}
                            control={<Radio />}
                            label="Black"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="1"
                            onChange={() => changePhoto(item.id, 1)}
                            control={<Radio />}
                            label="Gold"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="2"
                            onChange={() => changePhoto(item.id, 2)}
                            control={<Radio />}
                            label="Silver"
                            labelPlacement="bottom"
                          />
                        </RadioGroup>
                      </FormControl>
                    </CardActions>
                  </Card>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ backgroundColor: grey[800], p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom color="white">
          ABOUT US
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="white"
          component="p"
        >
          NEOZELTS LLC
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="white"
          component="p"
        >
          154 27th Ave, Fl 3 Brooklyn, New York 11214
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

const products = [
  {
    id: 0,
    title: "NZ1501",
    desctription: "Hook NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 0,
    images: ["/black.webp", "/gold.webp", "/silver.webp"],
    category: 1,
  },
  {
    id: 2,
    title: "NZ1502",
    desctription: "Hook NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 2,
    images: ["/hook1black.jpg", "/hook1gold.jpg", "/hook1silver.jpg"],
    category: 3,
  },
  {
    id: 3,
    title: "NZ1502",
    desctription: "Hook NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 1,
    images: ["/hook2black.jpg", "/hook2gold.jpg", "/hook2silver.jpg"],
    category: 2,
  },
  {
    id: 4,
    title: "NZ1801",
    desctription: "Towel ring NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 2,
    images: ["/ring1black.webp", "/ring1gold.webp", "/ring1silver.webp"],
    category: 1,
  },
  {
    id: 5,
    title: "NZ2101",
    desctription: "Paper Holdet NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 0,
    images: ["/paper1black.webp", "/paper1gold.webp", "/paper1silver.webp"],
    category: 1,
  },
  {
    id: 6,
    title: "NZ2102",
    desctription: "Paper Holdet NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 2,
    images: ["/paper2black.jpg", "/paper2gold.jpg", "/paper2silver.jpg"],
    category: 1,
  },
  {
    id: 7,
    title: "NZ3101",
    desctription: "Bathroom set NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 2,
    images: ["/set1black.webp", "/set1gold.webp", "/set1silver.webp"],
    category: 1,
  },
  {
    id: 8,
    title: "NZ3102",
    desctription: "Bathroom set NEOZELTZ",
    color: ["black", "gold", "silver"],
    imageIndex: 1,
    images: ["/set2black.webp", "/set2gold.webp", "/set2silver.webp"],
    category: 1,
  },
];
